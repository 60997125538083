<template>
  <div class="d-flex">
    <div
      class="ant-border-right ant-glass-background radius-0 pa-5 d-flex flex-column"
      style="width: 400px"
    >
      <v-subheader class="pl-0">
        <v-icon
          :disabled="!selectedProject"
          :style="{ opacity: selectedProject ? 1 : 0 }"
          @click="
            selectedProject = null;
            closeDetailView();
            closeProjectView;
          "
        >
          mdi-chevron-left
        </v-icon>
        {{ selectedProject ? `${selectedProject.name} modules` : 'Projects' }}
        <v-spacer />
        <v-tooltip v-if="selectedProject" bottom>
          <template #activator="{ on }">
            <v-btn icon @click="moduleScanner = true" v-on="on">
              <v-icon>mdi-qrcode-scan</v-icon>
            </v-btn>
          </template>
          <span>{{
            selectedProject ? 'Scan module' : 'Scan project & module'
          }}</span>
        </v-tooltip>

        <qr-scanner-dialog
          v-model="moduleScanner"
          title="Scan Module"
          @decoded="scannedModule"
        />
      </v-subheader>
      <transition mode="out-in" name="simple-fade">
        <div
          v-if="!selectedProject"
          key="mmd-1"
          class="d-flex flex-1 overflow-y-auto"
        >
          <transition mode="out-in" name="simple-fade">
            <div v-if="projectsStatus === 'success'" key="mmd-3">
              <div v-for="project in projects" :key="project.id">
                <div
                  class="module-dashboard-list-item pa-2"
                  @click="selectedProject = project"
                >
                  {{ project.name }}
                </div>
              </div>
            </div>
            <div
              v-else
              key="mmd-4"
              class="d-flex align-center justify-center flex-1"
            >
              <ant-loading />
            </div>
          </transition>
        </div>
        <div v-else key="mmd-2" class="d-flex flex-1 overflow-y-auto">
          <transition mode="out-in" name="simple-fade">
            <div
              v-if="projectModulesStatus === 'success'"
              key="mmd-5"
              class="full-width flex-1 d-flex flex-column"
            >
              <v-text-field
                v-model="moduleSearch"
                class="mb-2"
                clearable
                dense
                filled
                flat
                hide-details
                placeholder="Type here to search"
                prepend-inner-icon="mdi-magnify"
                rounded
                @click:clear="moduleSearch = ''"
              />
              <div class="overflow-y-auto full-height">
                <div v-for="module in filteredModules" :key="module.id">
                  <div
                    :class="{
                      'module-dashboard-list-item-active':
                        selectedModule === module,
                    }"
                    class="module-dashboard-list-item pa-2"
                    @click="
                      selectedModule === module
                        ? closeDetailView()
                        : (selectedModule = module)
                    "
                  >
                    {{ module.module_id }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="projectModulesStatus === 'error'"
              key="mmd-6"
              class="d-flex align-center justify-center flex-1 font-italic"
              style="font-size: 12px"
            >
              <v-icon class="mr-2" color="error"> mdi-alert-outline</v-icon>
              {{ projectModulesError }}
            </div>
            <div
              v-else-if="projectModulesStatus === 'loading'"
              key="mmd-6"
              class="d-flex align-center justify-center flex-1"
            >
              <ant-loading />
            </div>
          </transition>
        </div>
      </transition>
    </div>
    <div
      v-if="projectClient && selectedProject"
      class="flex-1 d-flex flex-column"
    >
      <portal to="ant-toolbar">
        <div></div>
      </portal>
      <ant-toolbar-container ant-toolbar-prefix="dhme-master-module" />
      <forge-viewer
        ref="forge-viewer"
        :ant-toolbar-options="{
          viewType: {
            display: true,
            enabled: false,
          },
          performanceMode: {
            display: true,
            enabled: true,
          },
          models: {
            display: false,
            enabled: false,
          },
          antTable: {
            display: false,
            enabled: false,
          },
          ghosting: {
            display: true,
            enabled: false,
          },
          modelTree: {
            display: false,
            enabled: false,
          },
          clearIsolation: {
            display: true,
            enabled: false,
          },
          objectProperties: {
            display: false,
            enabled: true,
          },
          sidebar: {
            display: false,
            enabled: true,
          },
        }"
        :client="projectClient"
        :extension-options="[]"
        :extensions="['Autodesk.DocumentBrowser', 'Autodesk.VisualClusters']"
        :models="projectModels"
        ant-toolbar-prefix="dhme-master-module"
        class="flex-1"
      />

      <div
        v-if="selectedModule"
        class="d-flex ant-border-top ant-glass-background radius-0"
        style="height: 500px"
      >
        <div class="ant-border-right pa-2 d-flex flex-column">
          <v-subheader class="pa-0">
            {{ selectedModuleIndex.module_id }}
          </v-subheader>
          <div
            v-for="key in Object.keys(selectedModule).filter(
              (key2) =>
                ![
                  'id',
                  'session',
                  'module_id',
                  'phase',
                  'assembly_location',
                ].includes(key2)
            )"
            :key="key"
            class="d-flex"
          >
            <div class="font-weight-bold">{{ key }}:</div>
            <v-spacer class="mx-2" />
            <div>{{ selectedModuleIndex[key] }}</div>
          </div>
          <v-spacer />
          <div class="d-flex ant-border-top pt-2 flex-column">
            <v-subheader class="pa-0 mb-2"> Apps</v-subheader>
            <div class="d-flex">
              <div
                v-for="(app, index) in moduleDashboardExternalApps"
                :key="`external_app_${index}`"
                class="d-flex align-center justify-center flex-1"
              >
                <a :href="app.url" target="_blank">
                  <img :src="getImgSrc(app.image)" alt="" style="" width="75" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <v-data-table
          :headers="moduleElementTableHeaders"
          :height="500 - 60"
          :items="moduleElements"
          class="ant-border-right"
          fixed-header
          @click:row="filterElementInModel"
        >
          <template #item.element_id="{ item }">
            <td
              :style="{
                'border-left':
                  item === selectedElement
                    ? 'solid 4px var(--v-primary-base)'
                    : 'none',
              }"
            >
              {{ item.element_id }}
            </td>
          </template>
          <template #item.element_level="{ item }">
            {{ findElementLevel(item) }}
          </template>
        </v-data-table>
        <div class="d-flex justify-center align-center flex-1 pa-2">
          <transition name="simple-fade">
            <v-btn
              v-if="!elementScanner"
              key="element_scanner_1"
              color="primary"
              large
              @click="elementScanner = true"
            >
              Scan element
            </v-btn>
            <qr-scanner-dialog
                v-model="elementScanner"
                title="Scan Element"
                @decoded="scannedElement"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading';
import { Portal } from 'portal-vue';
import AntToolbarContainer from '@/components/AntToolbarContainer';
import { DHME_M_MODULE_DASHBOARD } from '@/modules/modules';
import ForgeViewer from '@/components/Modules/Forge/ForgeViewer';
import { getObjectsWithMappingLevel } from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';
import { executeCustomModuleCall } from '@/services/api/module.api';
import QrScannerDialog from '@/components/Base/QrScannerDialog.vue';

export default {
  name: 'DHMEMModuleDashboard',
  components: {
    QrScannerDialog,
    Portal,
    AntToolbarContainer,
    ForgeViewer,
    AntLoading,
  },
  data: () => {
    return {
      selectedProject: null,
      selectedModule: null,
      selectedElement: null,
      projectModulesError: '',
      projectModulesStatus: '',
      projectModules: [],
      projectModels: [],
      projectModelMapping: null,
      projectClient: null,
      moduleSearch: '',
      moduleElements: [],
      moduleElementTableHeaders: [
        {
          text: 'element id',
          value: 'element_id',
        },
        {
          text: 'type',
          value: 'element_type',
        },
        {
          text: 'level',
          value: 'element_level',
        },
      ],
      moduleDashboardExternalApps: [
        {
          url: 'https://www.commant.nl/',
          image: 'Commant.png',
        },
        {
          url: 'https://jansnel-my.sharepoint.com/personal/t_de_swaaf_jansnel_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Ft%5Fde%5Fswaaf%5Fjansnel%5Fcom%2FDocuments%2FSMART%20Connected%20BIM%20%2D%20Fase%202%2FJS%20BIM%20Uitvoeringsmodel%20to%20Share',
          image: 'DaiwaQ.png',
        },
        { url: 'https://produuz.it/app/login', image: 'ProduuzIT.png' },
        {
          url: 'https://www.sap.com/',
          image: 'SAP.png',
        },
      ],
      moduleScanner: false,
      elementScanner: false,
    };
  },
  computed: {
    ...mapGetters(['projects', 'projectsStatus', 'project']),

    filteredModules() {
      return (
        this.moduleSearch.length > 0
          ? [...this.projectModules].filter((module) =>
              module.module_id.startsWith(this.moduleSearch)
            )
          : [...this.projectModules]
      ).sort((a, b) => a.module_id.localeCompare(b.module_id));
    },

    forgeViewer() {
      return this.$refs['forge-viewer'].viewerService.Viewer3D;
    },
    forgeViewerService() {
      return this.$refs['forge-viewer'].viewerService;
    },
    modelObjects() {
      return this.$refs['forge-viewer'].modelObjects.flatMap(
        (item) => item.properties
      );
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_MODULE_DASHBOARD
      ).id;
    },
  },
  watch: {
    selectedProject(value) {
      if (value) {
        this.fetchProjectModels();
        this.fetchProjectModules();
      }
    },
    selectedModule(value) {
      if (value) {
        this.filterModuleInModel();
        this.getModuleElements();
      } else {
        this.forgeViewer.showAll();
        this.forgeViewer.fitToView();
      }
      setTimeout(() => {
        this.forgeViewerService.resizeView();
      }, 10);
    },
  },
  mounted() {
    this.$store.dispatch('fetchProjects');
  },
  methods: {
    closeDetailView() {
      this.selectedElement = null;
      this.selectedModule = null;
      this.elementScanner = false;
      this.moduleScanner = false;
    },
    closeProjectView() {
      this.projectClient = null;
      this.projectModels = [];
      this.projectModelMapping = null;
      this.projectModules = [];
      this.moduleSearch = '';
    },
    getImgSrc(img) {
      return require(`../../../assets/DHME/${img}`);
    },
    fetchProjectModels() {
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'getProjectModels',
        {
          project: {
            id: this.selectedProject.id,
          },
        }
      )
        .then((response) => {
          this.projectModels = response.models;
          this.projectClient = response.client;
        })
        .catch((error) => {
          this.$store.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    },
    fetchProjectModules() {
      this.projectModulesStatus = 'loading';
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'getProjectModules',
        {
          project: {
            id: this.selectedProject.id,
          },
        }
      )
        .then((response) => {
          this.projectModules = response.modules;
          this.projectModelMapping = response.mapping;
          this.projectModulesStatus = 'success';
        })
        .catch((error) => {
          this.projectModulesError = error.message;
          this.projectModulesStatus = 'error';
        });
    },
    scannedModule(scannedResult) {
      // result should be module_id and optional projectId
      // TODO scan project and module
      if (this.selectedProject) {
        let module = this.projectModules.find(
          (module) => module.module_id === scannedResult
        );

        if (module) {
          this.filterModuleInModel(module);
          this.moduleScanner = false;
        } else {
          this.$store.commit('showNotification', {
            content: 'Module ID not found in this project',
            color: 'error',
          });
        }
      }
    },
    scannedElement(scannedResult) {
      // result should be element_id
      let element = this.moduleElements.find(
        (element) => element.element_id === scannedResult
      );

      if (element) {
        this.filterElementInModel(element);
        this.elementScanner = false;
      } else {
        this.$store.commit('showNotification', {
          content: 'Element not found in this module',
          color: 'error',
        });
      }
    },
    filterModuleInModel() {
      const moduleIdMappingLevel =
        this.projectModelMapping.module_id.split('.');
      let moduleObjects = getObjectsWithMappingLevel(
        this.modelObjects,
        moduleIdMappingLevel
      );

      let objects = moduleObjects.filter(
        (object) =>
          moduleIdMappingLevel.reduce((o, i) => o[i], object.properties) ===
          this.selectedModule.module_id
      );

      this.forgeViewer.isolate(objects.map((object) => object.objectid));
      this.forgeViewer.fitToView();
    },
    filterElementInModel(element) {
      this.selectedElement === element
        ? (this.selectedElement = null)
        : (this.selectedElement = element);
      if (this.selectedElement) {
        const moduleIdMappingLevel =
          this.projectModelMapping.element_id.split('.');
        let moduleObjects = getObjectsWithMappingLevel(
          this.modelObjects,
          moduleIdMappingLevel
        );

        let objects = moduleObjects.filter(
          (object) =>
            moduleIdMappingLevel.reduce((o, i) => o[i], object.properties) ===
            element.element_id
        );

        this.forgeViewer.isolate(objects.map((object) => object.objectid));
        this.forgeViewer.fitToView();
      } else {
        this.filterModuleInModel();
      }
    },
    getModuleElements() {
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'getModuleElements',
        {
          project: {
            id: this.selectedProject.id,
          },
          module_id: this.selectedModule.module_id,
        }
      )
        .then((response) => {
          this.moduleElements = response.elements;
        })
        .catch((error) => {
          this.$store.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    },
    findElementLevel(level) {
      let index = Object.values(this.projectModelMapping).findIndex(
        (value) => value === level.mapping_location
      );

      let key = Object.keys(this.projectModelMapping)[index];
      switch (key) {
        case 'composition_id':
          return 'Samenstelling';
        case 'element_id':
          return '1';
        case 'element_id_level_2':
          return '2';
        case 'element_id_level_3':
          return '3';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.module-dashboard-list-item {
  transition: 200ms;
  cursor: pointer;

  &:hover {
    background-color: white;
    border-left: solid 4px var(--v-primary-base);
  }

  &.module-dashboard-list-item-active {
    background-color: white;
    border-left: solid 4px var(--v-primary-base);
  }
}

.active-element {
  border-left: solid 4px var(--v-primary-base);
}

.module-dashboard-apps-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}
</style>
